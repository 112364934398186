import { useState, forwardRef, useContext, useEffect } from "react";
import { useNavigate, Navigate } from "react-router-dom";

import Snackbar from "@mui/material/Snackbar";
import Slide from "@mui/material/Slide";
import MuiAlert from "@mui/material/Alert";

import MobileView from "../views/login/mobile";
import DesktopView from "../views/login/desktop";

import service from "../service";
import { consts } from "../const";
import { AgentContext } from "../context";

const Login = ({ isMobile }) => {
  const navigate = useNavigate();
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
  const [message, setMessage] = useState(undefined);
  const [severity, setSeverity] = useState(undefined);

  const { state, setUser } = useContext(AgentContext);

  const [isLoading, setIsLoading] = useState(false);
  const Alert = forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const onLogin = (email, password) => {
    setIsLoading(true);
    service
      .onLogin(email.toLowerCase(), password)
      .then(async (res) => {
        localStorage.setItem("agent_token", res.token);

        const user = service.getUserInfo(res.token);
        const currentUser = await service.getCurrentAgent(user.user);
        setUser({
          ...currentUser[0],
          exp: user.exp,
        });
        navigate("/chat");
      })
      .catch((err) => {
        console.log(err);
        if (err.status === consts.SCHEMA_ERROR_CODE) {
          if (err.dataResponse.error === '"email" is not allowed to be empty') {
            setMessage("El correo no puede estar vacio");
          } else if (
            err.dataResponse.error === '"password" is not allowed to be empty'
          ) {
            setMessage("La clave no puede estar vacia");
          } else {
            setMessage("La clave debe ser mayor a 3 caracteres");
          }
        } else {
          if (err.dataResponse.msg === "session/duplicate") {
            setMessage("Sesión duplicada");
          } else if (err.dataResponse.msg === "user/notVerify") {
            setMessage("Usuario no verificado");
          } else if (err.dataResponse.msg === "user/inactive") {
            setMessage(
              "Cuenta deshabilitada, póngase en contacto con el administrador"
            );
          } else {
            setMessage("Los datos no son correctos");
          }
        }

        setSeverity("error");
        setIsSnackbarOpen(true);
        setIsLoading(false);
      });
  };

  const SlideTransition = (props) => {
    return <Slide {...props} direction="left" />;
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setIsSnackbarOpen(false);
  };

  const navigateToRecover = () => {
    navigate("/recover");
  };

  return (
    <>
      {!state.user ? (
        isMobile ? (
          <MobileView
            onLogin={onLogin}
            isLoading={isLoading}
            navigateToRecover={navigateToRecover}
          />
        ) : (
          <DesktopView
            onLogin={onLogin}
            isLoading={isLoading}
            navigateToRecover={navigateToRecover}
          />
        )
      ) : (
        <Navigate to="/chat" />
      )}
      <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        open={isSnackbarOpen}
        onClose={handleCloseSnackbar}
        TransitionComponent={SlideTransition}
      >
        <Alert severity={severity}>{message}</Alert>
      </Snackbar>
    </>
  );
};

export default Login;
