import FacebookIcon from "../assets/IconoFacebook2.svg";
import VideoLinkIcon from "../assets/CC.svg";
import InstagramIcon from "../assets/IconoInstagram2.svg";
import TelegramIcon from "../assets/IconoTelegram2.svg";
import WhatsappIcon from "../assets/IconoWhatsapp2.svg";
import WidgetIcon from "../assets/Widget.svg";

export const getCanalIcon = (canal) => {
  switch (canal) {
    case "facebook":
      return FacebookIcon;
    case "web-widget":
      return WidgetIcon;
    case "instagram":
      return InstagramIcon;
    case "web":
      return VideoLinkIcon;
    case "telegram":
      return TelegramIcon;
    case "whatsapp":
      return WhatsappIcon;
    default:
      return false;
  }
};

export const getText = (message) => {
  switch (message.trim()) {
    case "spam":
      return "Spam";
    case "outContext":
      return "Fuera de contexto";
    case "graphContent":
      return "Contenido gráfico";
    case "bullying":
      return "Acoso";
    default:
      return "Odio e intolerancia";
  }
};
